<template>
  <v-text-field
    dense
    outlined
    counter
    maxlength="20"
    @keyup.enter="$emit('keyupEnter')"
    :class="inputClass"
    :label="passwordLabel"
    :rules="rules"
    :type="inputType"
    v-model="password"
  >
    <template v-slot:append>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-icon
            @click="toggleInputType"
            v-on="on"
            class="cursor-pointer pt-1"
          >fa fa-eye</v-icon>
        </template>

        <span>{{'action.view_password'| t}}</span>
      </v-tooltip>
    </template>
  </v-text-field>
</template>

<script lang="babel" type="text/babel">
export default {
  props: {
    inputClass: {
      type: [String, Array, null],
      default: () => null,
    },
    label: {
      type: String,
      default: null,
    },
    value: {
      type: [String, Number, null, undefined],
      default: null,
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    password: null,
    inputType: 'password',
  }),
  methods: {
    toggleInputType() {
      if(this.inputType === 'password') {
        this.inputType = 'text'
        return
      }

      this.inputType = 'password'
    },
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.password = this.value
      },
    },
    password: {
      handler() {
        if(window.eagleLodash.isEqual(this.password === this.value)) return
        this.$emit('input', window.eagleLodash.cloneDeep(this.password))
      },
    },
  },
  computed: {
    passwordLabel() {
      if(this.label) return this.label
      return this.$t('data.password')
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
